/**
 * Created by zhou on 2020/04/14.
 */

export const REG = {
  USERNAME: /^[a-zA-Z0-9]{6,20}$/,
  // PHONE: /^(13[0-9]|15[012356789]|17[012356789]|18[0-9]|14[57]|16[6]|19[012356789])[0-9]{8}$/, // 手机号
  PHONE: /^1[0-9][0-9]\d{8}$/, // 手机号
  IDCARD: /^(\d{15}$|^\d{18}$|^\d{17}(\d|X|x))$/, // 身份证号
  SOCIALCODE: /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10})|^([a-zA-Z0-9]{22})$/, // 统一社会信用代码
  EMAIL: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/, // 邮箱
  QQ: /^[1-9][0-9]{3,9}$/, // QQ
  TELEPHONE: /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/, // 座机号
  HOTLINE: /^400(-\d{3,4}){2}$/, // 400客服电话
  PASSWORD: /^[^\s]{6,20}$/,
  NATION: /^[\u4E00-\u9FA5·]+$/,
  decimal: /^(0|[1-9]\d{0,9})(\.\d{1,2})?$/, // 收入 小数点后最多输入2位数字，或者输入1~10位的整数(2位以上数字，首字符不可为‘0’，例如：‘01’)。
  decimal14: /^(0|[1-9]\d{0,13})(\.\d{1,2})?$/, // 收入 小数点后最多输入2位数字，或者输入1~14位的整数(2位以上数字，首字符不可为‘0’，例如：‘01’)。
  // eslint-disable-next-line no-useless-escape
  URL: /(https|http):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/,
  unitName: /^[a-zA-Z0-9\u4e00-\u9fa5]{1,50}$/,
  guhua: /^0\d{2,3}-\d{1,8}$/,
  POST: /^[1-9][0-9]{0,9}$/,//开头不能为0，共10位
  POSTNUM: /^[0-9]*$/,//整数和0

}
